import React from 'react';
import PropTypes from 'prop-types';

import './Alert.sass';

const Alert = ({ message = '', type = 'success', onClose = () => undefined }) => {
  return (
    <div className={`alert ${type}-alert`}>
      <h3>{message}</h3>
      <a onClick={onClose} className="close">
        &times;
      </a>
    </div>
  );
};

Alert.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string,
  onClose: PropTypes.func
};

export default Alert;
