import * as openpgp from 'openpgp';

const publicKeyArmored = new URL(document.location).searchParams.get('key')?.replace(/\\n/g, '\n');

export async function encryptData(data) {
  const publicKey = await openpgp.readKey({ armoredKey: publicKeyArmored });

  return openpgp.encrypt({
    message: await openpgp.createMessage({ text: JSON.stringify(data) }),
    encryptionKeys: publicKey
  });
}
