import React from 'react';
import PropTypes from 'prop-types';

import './SubmitButton.sass';

function SubmitButton({ text = 'Submit', className = 'btn-submit', disabled = false }) {
  return (
    <button className={className} type="submit" disabled={disabled}>
      {text}
    </button>
  );
}

SubmitButton.propTypes = {
  text: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string
};

export default SubmitButton;
