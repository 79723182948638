import { encryptData } from './GpgService';

const API_URL = new URL(document.referrer).origin + '/wp-json/hipaa-forms/v1';
const REDIRECT_URL = new URL(document.location).searchParams.get('thankYouPage');
const GTM_DATA = { GA: undefined, FBPixel: undefined };

export function setGTMData(data) {
  GTM_DATA.clientId = data.clientId;
  GTM_DATA.uTMSource = data.uTMSource;
  GTM_DATA.fbc = data.fbc;
  GTM_DATA.fbp = data.fbp;
}

export function formatData(data, formSettings) {
  return Object.keys(data).map((k) => {
    const field = formSettings.fields.find((f) => f.key === k);
    return {
      key: k,
      name: field?.name,
      value: field?.type === 'date' ? data[k].toLocaleDateString('en-US') : data[k] || undefined,
      label: field?.options?.find((o) => o.value === +data[k])?.label,
      isHidden: false
    };
  });
}

export async function sendHipaaForm(type, data, token) {
  const { fbc, fbp, clientId, uTMSource } = GTM_DATA;
  const gtmData = [
    { key: 'GA', value: JSON.stringify({ clientId, uTMSource }), name: 'GA', isHidden: true },
    { key: 'FBPixel', value: JSON.stringify({ fbp, fbc }), name: 'FBPixel', isHidden: true }
  ];
  const encryptedData = await encryptData({ fields: [...data, ...gtmData] });
  let response;

  response = await fetch(`${API_URL}/${type}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: encryptedData, token })
  });

  if (response.status !== 200) {
    throw new Error('');
  }

  if (REDIRECT_URL) {
    window.parent?.postMessage(
      { type: 'hipaa-form:submitted', redirectUrl: REDIRECT_URL },
      new URL(document.referrer).origin
    );
  } else {
    return await response.json();
  }
}
